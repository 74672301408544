import React from 'react'

import SpotifyURI from 'spotify-uri'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, P, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'
import VideoLink from '../shared/video-link'

const BlockPodcast = ({ node }, ...rest) => {
    const parsed = SpotifyURI.parse(node.spotifyUri)

    const embedUrl = `https://open.spotify.com/embed-podcast/${parsed.type}/${parsed.id}`

    return (
        <Container>
            <iframe
                src={embedUrl}
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
            ></iframe></Container>
    )
}

const Container = styled.div`
    margin-bottom: ${Spacing.xxxl};
    padding: 0 10vw;
`

export default BlockPodcast
