import React, { useState } from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { BREAKPOINTS, GRIDS } from './shared/grid'
import Spacing from './shared/spacing'

import BlockContentList from './blocks/content-list'
import BlockExpertList from './blocks/expert-list'
import BlockFormEmbed from './blocks/block-external-embed'
import BlockNewsletter from './blocks/newsletter'
import BlockPodcast from './blocks/podcast'
import BlockVideo from './blocks/video'

import EntryCompany from './entries/company'
import EntryExpert from './entries/expert'
import EntryStandardPage from './entries/standard-page'

import ImageWithSVGSupport from './shared/image-with-svg-support'
import TextLink from './shared/text-link'
import {
    P,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    UL,
    OL,
    BLOCKQUOTE,
} from './shared/typography'

const Bold = ({ children }) => <strong>{children}</strong>

// Marks - BOLD, ITALIC, UNDERLINE, CODE

// Blocks - DOCUMENT, PARAGRAPH, HEADING_1 thru 6, OL_LIST, UL_LIST, LIST_ITEM
//  HR, QUOTE, EMBEDDED_ENTRY, EMBEDDED_ASSET

// Inlines - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK, EMBEDDED_ENTRY

let evenOddFlag = false

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Paragraph>{children}</Paragraph>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
        [BLOCKS.HEADING_2]: (node, children) => (
            <Heading2 size={'h3'} margin={`${Spacing.xxl} 0 0 0`}>
                {children}
            </Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <Heading3 size={'h4'}>{children}</Heading3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
            <Heading4 size={'h5'}>{children}</Heading4>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
            <Heading5 size={'h6'}>{children}</Heading5>
        ),
        [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
        [BLOCKS.OL_LIST]: (node, children) => (
            <OrderedList>{children}</OrderedList>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <UnorderedList>{children}</UnorderedList>
        ),
        [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
        [BLOCKS.HR]: (node, children) => (
            <div>
                <hr />
            </div>
        ),
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            // If you are using contenful.js sdk, the referenced entry is resolved
            // automatically and is available at `node.data.target`.
            // const referencedEntry = getEntryWithId(node.data.target.sys.id);
            // <a href={`/pages/${referencedEntry.fields.slug}`}>{children}</a>
            // return 'test';

            return (
                <TextLink
                    children={children}
                    node={node.data.target}
                    activeClassName="active"
                />
            )
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
            if (typeof node.data.target.file === 'undefined') {
                return <span></span>
            }

            return (
                <TextLink
                    children={children}
                    uri={node.data.target.file.url}
                    activeClassName="active"
                />
            )
        },
        [INLINES.HYPERLINK]: (node, children) => {
            // If you are using contenful.js sdk, the referenced entry is resolved
            // automatically and is available at `node.data.target`.
            // const referencedEntry = getEntryWithId(node.data.target.sys.id);
            // <a href={`/pages/${referencedEntry.fields.slug}`}>{children}</a>
            // return 'test';
            return <TextLink uri={node.data.uri} children={children} />
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => <EmbeddedEntry node={node} />,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const content = node

            const { title, description, file } = content.data.target

            if (typeof file === 'undefined') {
                return <p>Asset Error</p>
            }

            const mimeType = file.contentType
            const mimeGroup = mimeType.split('/')[0]
            const mimeSet = mimeType.split('/')[1]

            switch (mimeGroup) {
                case 'image':
                    const contentfulImage = content.data.target

                    const width = contentfulImage.file.details.image.width
                    const height = contentfulImage.file.details.image.height

                    const ratio = width / height
                    const micro = height <= 200 || width <= 400

                    return (
                        <Image
                            title={content.data.target.title}
                            fluid={contentfulImage.fluid}
                            file={file}
                            svg={contentfulImage.svg}
                        />
                    )
                case 'application':
                    return (
                        <a
                            alt={description ? description : null}
                            href={file.url}
                        >
                            {title ? title : file.details.fileName}
                        </a>
                    )
                default:
                    return (
                        <span
                            style={{ backgroundColor: 'red', color: 'white' }}
                        >
                            {' '}
                            {mimeType} embedded asset{' '}
                        </span>
                    )
            }
        },
    },
}

const blocksHandlers = {
    ContentfulBlockContentList: (value) => <ContentList node={value} />,
    ContentfulBlockPodcast: (value) => <Podcast node={value} />,
    ContentfulBlockExpertList: (value) => <ExpertList node={value} />,
    ContentfulBlockFormEmbed: (value) => <Form node={value} />,
    ContentfulBlockNewsletter: (value) => <Newsletter node={value} />,
    ContentfulBlockVideo: (value) => <Video node={value} />,
    ContentfulCompany: (value) => (
        <EntryContainer>
            <EntryCompany node={value} />
        </EntryContainer>
    ),
    ContentfulExpert: (value, isEven) => (
        <EntryContainer>
            <EntryExpert
                firstName={value.firstName}
                lastName={value.lastName}
                jobTitle={value.jobTitle}
                experience={value.experience}
                summary={value.summary}
                headshot={value.headshot}
                company={value.relatedCompany}
                indexIsEven={isEven}
            />
        </EntryContainer>
    ),
    ContentfulStandardPage: (value) => (
        <EntryContainer>
            <EntryStandardPage node={value} />
        </EntryContainer>
    ),
    default: (value) => <Placeholder value={value} />,
}

const Placeholder = ({ value }) => {
    console.log(value)
    return <div>Placeholder</div>
}

function EmbeddedEntry({ node }) {
    if (typeof node.data.target === 'undefined') {
        return <span></span>
    }

    const type =
        typeof node.data.target.sys.contentType === 'undefined'
            ? node.data.target.__typename
            : node.data.target.sys.contentType.sys.id
    const value = get(node, 'data.target')
    const handler = blocksHandlers[type] || blocksHandlers.default

    evenOddFlag = !evenOddFlag

    const isEven = evenOddFlag ? 1 : 0

    return handler(value, isEven)
}

const ContentList = ({ node }) => {
    return (
        <EntryContainer>
            <BlockContentList
                displayType={node.displayType}
                heading={node.primaryHeading}
                relatedContent={node.relatedContent}
            />
        </EntryContainer>
    )
}

const ExpertList = ({ node }) => {
    return (
        <EntryContainer>
            <BlockExpertList
                primaryHeading={node.primaryHeading}
                summary={node.summary}
                relatedExperts={node.relatedExperts}
                relatedPage={node.relatedPage}
            />
        </EntryContainer>
    )
}

const Form = ({ node }) => {
    return (
        <EmbedContainer>
            <BlockFormEmbed url={node.formUrl} popup={false} />
        </EmbedContainer>
    )
}

const Newsletter = ({ node }) => {
    return (
        <EmbedContainer>
            <BlockNewsletter node={node} />
        </EmbedContainer>
    )
}

const Podcast = ({ node }) => {
    return (
        <EmbedContainer>
            <BlockPodcast node={node} />
        </EmbedContainer>
    )
}

const Video = ({ node }) => {
    return (
        <EntryContainer>
            <BlockVideo node={node} />
        </EntryContainer>
    )
}

const Container = ({ className, data }, ...rest) => {
    const hasJSON = data !== null && typeof data !== 'undefined'

    if (!hasJSON) {
        return null
    }

    return <>{renderRichText(data, options)}</>
}

const ContentGrid = css`
    padding: 0 10vw;
    grid-column: left-gutter / end;

    @media ${BREAKPOINTS.mobile} {
        padding: 0;
        grid-column: main / right-gutter;
    }
`

const EmbedGrid = css`
    padding: 0;
    grid-column: left-gutter / end;

    @media ${BREAKPOINTS.mobile} {
        padding: 0;
        grid-column: left-gutter / -1;
    }
`

const EntryGrid = css`
    grid-column: left-gutter / end;
`

const EntryContainer = styled.div`
    ${EntryGrid}
`

const EmbedContainer = styled.div`
    ${EmbedGrid}
`

const Paragraph = styled(P)`
    ${ContentGrid}
`
const Heading1 = styled(H1)`
    ${ContentGrid}
`
const Heading2 = styled(H2)`
    ${ContentGrid}
`
const Heading3 = styled(H3)`
    ${ContentGrid}
`
const Heading4 = styled(H4)`
    ${ContentGrid}
`
const Heading5 = styled(H5)`
    ${ContentGrid}
`
const Heading6 = styled(H6)`
    ${ContentGrid}
`
const UnorderedList = styled(UL)`
    ${ContentGrid}
`
const OrderedList = styled(OL)`
    ${ContentGrid}
`
const Blockquote = styled(BLOCKQUOTE)`
    ${ContentGrid}
`

const Image = styled(ImageWithSVGSupport)`
    ${ContentGrid}
`

export default Container
