import React from 'react'
import styled, { css } from 'styled-components'

import Spacing from './shared/spacing'

import { P, typestyle } from './shared/typography'
import Link from './shared/text-link'

const linkResolver = require('../utils').linkResolver

const CallToAction = ({ node, url, children, formUrl, inverted }) => {
    const to = !url ? linkResolver.path(node) : url
    const isExternal = typeof url !== 'undefined' && url !== null
    const isInverted = typeof inverted !== 'undefined' ? inverted : 1

    const isForm = typeof formUrl !== 'undefined' && formUrl !== null

    return (
        <P>
            {isExternal && !isForm && (
                <Link
                    uri={to}
                    inverted={isInverted}
                >
                    {children}
                </Link>
            )}
            {!isExternal && !isForm && (
                <Link
                    node={to}
                    inverted={isInverted}
                >
                    {children}
                </Link>
            )}
            {!isExternal && isForm && (
                <Link
                    formUrl={formUrl}
                    inverted={isInverted}
                >
                    {children}
                </Link>
            )}
            { isInverted === 0 && (
                <Icon src='/arrow-long-right-inverse.svg' />
            )}
            { isInverted === 1 && (
                <Icon src='/arrow-long-right.svg' />
            )}
        </P>
    )
}

const Icon = styled.img`
    position: relative;
    top: .2em;
    display: inline-block;
    margin-left: .5em;
    height: 1em;
`

export default CallToAction