import React from 'react'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, P, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'

const EntryStandardPage = ({ node }, ...rest) => {
    return (
        <Container>
            {node.thumbnailMedia && (
                <Media>
                    <ImageWrapper>
                        <Image
                            fluid={node.thumbnailMedia.fluid}
                            file={node.thumbnailMedia.file}
                        />
                    </ImageWrapper>
                </Media>
            )}
            <Content>
                <H2>
                    {node.primaryHeading ? node.primaryHeading : node.title}
                </H2>
                {node.summary && <P>{node.summary.summary}</P>}
                <CallToAction node={node} inverted={1}>
                    Read More
                </CallToAction>
            </Content>
        </Container>
    )
}

const Container = styled.section`
    padding: 5vw 10vw;
    background-color: ${Theme.ui.inverse};
    color: ${Theme.text.inverse};

    @media ${BREAKPOINTS.mobile} {
        display: grid;
        grid-template-columns: 4fr 3fr;
    }
`

const Media = styled.div`
    @media ${BREAKPOINTS.mobile} {
        margin-bottom: ${Spacing.md};
        order: 2;
    }
`

const ImageWrapper = styled.div`
    position: relative;

    &::after {
        position: absolute;
        left: -4px;
        top: -4px;
        right: 70%;
        bottom: 70%;
        display: block;
        background-color: ${Theme.brand.primary};
        content: '';
        z-index: 0;
    }

    &::before {
        position: absolute;
        left: 30%;
        top: 40%;
        right: -4px;
        bottom: -4px;
        display: block;
        background-color: ${Theme.brand.primary};
        content: '';
        z-index: 0;
    }
`

const Image = styled(ImageWithSVGSupport)`
    position: relative;
    background-color: ${Theme.ui.inverse};
    object-fit: cover;
    z-index: 10;

    @media ${BREAKPOINTS.mobile} {
    }

    img {
        // filter: grayscale(1);
    }
`

const Content = styled.div`
    white-space: pre-wrap;

    @media ${BREAKPOINTS.mobile} {
        padding-right: ${Spacing.lg};
        order: 1;
    }
`

export default EntryStandardPage
