import React from 'react'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, H4, P, CAPTION, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'
import { Link as GatsbyLink } from 'gatsby'
import TextLink from '../shared/text-link'

const linkResolver = require('../../utils').linkResolver

const BlockContentList = (
    { displayType, heading, relatedContent },
    ...rest
) => {
    return (
        <Container>
            {heading && <H2 margin={'0 2vw'}>{heading}</H2>}
            {displayType == 'Summary' && (
                <SummaryList relatedContent={relatedContent} />
            )}
            {displayType == 'Thumbnail' && (
                <ThumbnailList relatedContent={relatedContent} />
            )}
        </Container>
    )
}

const BlockContent = ({
    node,
    uri,
    thumbnail,
    displayType,
    expert,
    primaryHeading,
    overline,
    secondaryHeading,
    index,
}) => {
    const to = !uri ? linkResolver.path(node) : uri
    const isExternal = uri ? true : false

    return (
        <ContentSummary index={index}>
            {thumbnail && !isExternal && (
                <GatsbyLink to={to}>
                    <Thumbnail fluid={thumbnail.fluid} file={thumbnail.fluid} cover={'cover'} />
                </GatsbyLink>
            )}
            {thumbnail && isExternal && (
                <a href={node.externalUrl}>
                    <Thumbnail fluid={thumbnail.fluid} file={thumbnail.fluid} cover={'cover'} />
                </a>
            )}
            <MetaInfo index={index}>
                {index === 0 && <ArrowIcon src={'/arrow-right.svg'} />}
                {expert && (
                    <CAPTION>
                        {displayType === 'Work' ? `'Work by ${expert.firstName} ${expert.lastName}` : `${expert.firstName} ${expert.lastName} Answers`}
                    </CAPTION>
                )}
                {overline && (
                    <CAPTION>
                        {overline}
                    </CAPTION>
                )}
                <H4 margin={`${Spacing.sm} 0`}>
                    <GatsbyLink to={to}>{primaryHeading}</GatsbyLink>
                </H4>
                {secondaryHeading && index === 0 && <P>{secondaryHeading}</P>}
                {index === 0 && (
                    <GatsbyLink to={to}>
                        {displayType === 'Podcast'
                            ? 'Listen to the Experts'
                            : 'Read More'}
                        <ChevronIcon src={'/chevron-down-color.svg'} />
                    </GatsbyLink>
                )}
            </MetaInfo>
        </ContentSummary>
    )
}

const ThumbnailContent = ({
    node,
    thumbnail,
    primaryHeading,
    secondaryHeading,
    className,
}) => {
    const to = node.internalLink
        ? linkResolver.path(node.internalLink)
        : linkResolver.path(node)
    const isExternal = node.externalUrl ? true : false

    return (
        <div className={className}>
            {thumbnail && !isExternal && (
                <GatsbyLink to={to}>
                    <Thumbnail fluid={thumbnail.fluid} file={thumbnail.fluid} />
                </GatsbyLink>
            )}
            {thumbnail && isExternal && (
                <a href={node.externalUrl}>
                    <Thumbnail fluid={thumbnail.fluid} file={thumbnail.fluid} />
                </a>
            )}
            <div>
                <H4 margin={`${Spacing.sm} 2vw`}>
                    <TextLink
                        node={node.internalLink ? node.internalLink : node}
                        uri={node.externalUrl}
                        inverted={1}
                    >
                        {primaryHeading}
                    </TextLink>
                </H4>
                {secondaryHeading && <P>{secondaryHeading}</P>}
            </div>
        </div>
    )
}

const SummaryList = ({ relatedContent }) => {
    return (
        <List>
            {relatedContent.map((content, index) => (
                <BlockContent
                    node={content}
                    uri={content.externalUrl}
                    index={index}
                    key={`block-content-${content.id}`}
                    thumbnail={
                        content.thumbnailMedia || content.thumbnail
                            ? content.thumbnailMedia || content.thumbnail
                            : content.leadImage
                    }
                    displayType={content.displayType}
                    expert={content.relatedExpert}
                    primaryHeading={
                        content.primaryHeading || content.displayTitle
                    }
                    overline={content.overline}
                    secondaryHeading={content.secondaryHeading}
                />
            ))}
        </List>
    )
}

const ThumbnailList = ({ relatedContent }) => {
    return (
        <GridList>
            {relatedContent.map((content, index) => (
                <Thumb
                    key={`thumbnail-${content.id}`}
                    node={content}
                    thumbnail={
                        content.thumbnail
                            ? content.thumbnail
                            : content.thumbnailMedia
                    }
                    primaryHeading={
                        content.displayTitle
                            ? content.displayTitle
                            : content.primaryHeading
                    }
                />
            ))}
        </GridList>
    )
}

const Container = styled.div`
    padding: 4vw 8vw;
    background-color: ${Theme.ui.inverse};
    color: ${Theme.text.inverse};
`

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const GridList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${Spacing.md};
`

const Thumb = styled(ThumbnailContent)`
    display: grid;
    grid-template-rows: 2fr 1fr;
`

const ContentSummary = styled.div`
    display: grid;
    flex: 1 1 ${(props) => (props.index === 0 ? '80vw' : '80vw')};
    margin: 3vw 2vw;
    grid-template-columns: ${(props) => (props.index === 0 ? '1fr' : '1fr')};
    grid-template-rows: ${(props) => (props.index === 0 ? '1fr' : '1fr 1fr')};

    border-bottom: 6px solid
        ${(props) =>
            props.index === 0 ? 'transparent' : `${Theme.brand.primary}`};

    @media ${BREAKPOINTS.tablet} {
        flex: 0 1 ${(props) => (props.index === 0 ? '80vw' : '23vw')};
        grid-template-columns: ${(props) =>
            props.index === 0 ? '2fr 1fr' : '1fr'};
    }

    @media ${BREAKPOINTS.desktop} {
        flex: 0 1 ${(props) => (props.index === 0 ? '56vw' : '16vw')};
    }

    a:link,
    a:visited {
        text-decoration: none;
        color: currentColor;
    }

    a:hover,
    a:active {
        color: ${Theme.text.hover};
    }
`

const MetaInfo = styled.div`
    padding: ${Spacing.md};
    background-color: ${(props) =>
        props.index !== 0 ? 'transparent' : `${Theme.ui.primary}`};
    color: ${(props) =>
        props.index !== 0 ? `${Theme.text.inverse}` : `${Theme.text.primary}`};
`

const ArrowIcon = styled.img`
    width: 2rem;
    transform: rotate(90deg);
`

const ChevronIcon = styled.img`
    display: inline-block;
    padding: 0 ${Spacing.xs};
    height: 0.5em;
    transform: rotate(-90deg);
`

const Thumbnail = styled(ImageWithSVGSupport)`
    height: 100%;
    object-fit: cover;
`

export default BlockContentList
