import React from 'react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS } from '../shared/grid'
import Theme from '../shared/colors'
import Spacing from '../shared/spacing'

import { H2, H3 } from '../shared/typography'
import BlockExternalEmbed from './block-external-embed'

const Newsletter = () => {
    const formUrl =
        'https://admin075583.typeform.com/to/hMJwaJWA?typeform-welcome=0'

    return (
        <Container>
            <Signup>
                <H2 size='h3'>Sign Up for Our Emails</H2>
                <H3 size='h4'>Join our mailing list and don't miss a thing.</H3>
                <BlockExternalEmbed url={formUrl} />
            </Signup>
        </Container>
    )
}

const Container = styled.div`
    padding: ${Spacing.xxxl} 10vw ${Spacing.xl} 10vw;
    width: 100vw;
    background-color: ${Theme.ui.primary};
    text-align: right;
    color: ${Theme.text.primary};

    @media ${BREAKPOINTS.mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`

const Signup = styled.section`
    grid-column: span 2;
    text-align: center;
`

export default Newsletter
