import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Spacing from './spacing'
import Theme from './colors'

import Modal from 'react-modal'
import ReactPlayer from 'react-player'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby')

export default ({ uri, children }, ...rest) => {
    const [modalIsOpen, setIsOpen] = useState(false)

    function handleOpenModal(e) {
        e.preventDefault()
        setIsOpen(true)
    }

    function handleCloseModal(e) {
        e.preventDefault()
        setIsOpen(false)
    }

    return (
        <span>
            <VideoLink onClick={handleOpenModal}>{children}</VideoLink>
            <VideoModal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                contentLabel="Video Modal"
            >
                <PlayerWrapper>
                    <VideoPlayer url={uri} width="100%" height="100%" />
                </PlayerWrapper>
                <Close src="/logo-icon.svg" onClick={handleCloseModal} />
            </VideoModal>
        </span>
    )
}

const VideoLink = styled.span`
    cursor: pointer;
`

const VideoModal = styled(Modal)`
    position: absolute;
    left: 5vw;
    right: 5vw;
    bottom: 5vh;
    top: 5vh;
    display: flex;
    padding: 5vh 5vw;
    justify-content: center;
    align-content: center;
    background: ${Theme.ui.primary};
    z-index: 1000;
`

const Close = styled.img`
    position: absolute;
    top: ${Spacing.md};
    right: ${Spacing.md};
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 100;
`

const PlayerWrapper = styled.div`
    position: relative;
    width: 100%;
`

const VideoPlayer = styled(ReactPlayer)`
`
