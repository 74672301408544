import React from 'react'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, P, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'
import ReactPlayer from 'react-player'
import VideoLink from '../shared/video-link'

const BlockVideo = ({ node }, ...rest) => {
    return (
        <Container>
            {node.videoCover && (
                <VideoPlayer
                    url={node.videoUrl}
                    width="100%"
                    config={{
                        vimeo: {
                            playerOptions: {
                                controls: true,
                                playsinline: true,
                            },
                        },
                    }}
                />
            )}
            <Content>
                <H2>
                    {node.primaryHeading ? node.primaryHeading : node.title}
                </H2>
                {node.summary && <Summary>{node.summary.summary}</Summary>}
            </Content>
        </Container>
    )
}

const Container = styled.section`
    padding: 5vw 10vw;
    background-color: ${Theme.ui.inverse};
    color: ${Theme.text.inverse};

    @media ${BREAKPOINTS.mobile} {
        display: grid;
        grid-template-columns: 3fr 4fr;
    }
`

const Summary = styled(P)`
    white-space: pre-wrap;
`

const Image = styled(ImageWithSVGSupport)`
    position: relative;
    margin-bottom: ${Spacing.md};
    max-height: 60vh;

    @media ${BREAKPOINTS.mobile} {
        order: 2;
    }

    img {
        // filter: grayscale(1);
    }

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-image: url('/arrow-play.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30% 30%;
        content: '';
    }
`

const Content = styled.div`
    @media ${BREAKPOINTS.mobile} {
        padding-left: ${Spacing.lg};
        order: 1;
    }
`

const VideoPlayer = styled(ReactPlayer)``

export default BlockVideo
