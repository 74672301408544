import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, P, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'
import { Link as GatsbyLink } from 'gatsby'
import TextLink from '../shared/text-link'

const linkResolver = require('../../utils').linkResolver

const BlockExpertList = ({
    title,
    primaryHeading,
    summary,
    relatedExperts,
    relatedPage,
}) => {
    const scrollingContainer = useRef()
    const [scrollingWidth, setScrollingWidth] = useState(0)

    const clickHandler = () => {
        scrollingContainer.current.scrollBy({
            top: 0,
            left: scrollingWidth,
            behavior: 'smooth',
        })
    }

    return (
        <ExpertList>
            <ExpertCopy>
                <H2>{primaryHeading}</H2>
                {summary && <P>{summary.summary}</P>}
                {relatedPage && (
                    <CallToAction node={relatedPage} inverted={1}>
                        Ask Brand X
                    </CallToAction>
                )}
            </ExpertCopy>
            {relatedExperts && (
                <ExpertsContainer>
                    <Wrapper>
                        <Experts ref={scrollingContainer}>
                            {relatedExperts.map((expert, index) => (
                                <Expert
                                    key={`expert-list-${expert.id}`}
                                    firstName={expert.firstName}
                                    lastName={expert.lastName}
                                    jobTitle={expert.jobTitle}
                                    photo={expert.photo}
                                    headshot={expert.headshot}
                                    company={expert.relatedCompany}
                                    setScrollingWidth={setScrollingWidth}
                                />
                            ))}
                        </Experts>
                        <RightArrow
                            src="/arrow-right.svg"
                            onClick={clickHandler}
                        />
                    </Wrapper>
                </ExpertsContainer>
            )}
        </ExpertList>
    )
}

const Expert = ({
    firstName,
    lastName,
    jobTitle,
    photo,
    headshot,
    company,
    setScrollingWidth,
}) => {
    const ref = useRef()

    
    const to = company ? linkResolver.path(company) : '/'

    useEffect(() => {
        setScrollingWidth(ref.current.clientWidth)
    }, [])

    return (
        <ExpertThumb ref={ref}>
            {headshot && headshot.fluid && (
                <Headshot>
                    <GatsbyLink href={to}><Photo fluid={headshot.fluid} file={headshot.file} /></GatsbyLink>
                </Headshot>
            )}
            <figcaption>
                <H3 size={'h5'}>
                    {firstName} {lastName}
                </H3>
                <P>{jobTitle}</P>
                {company && (
                    <P size='caption'><TextLink node={company}>Ask {firstName}</TextLink></P>
                )}
            </figcaption>
        </ExpertThumb>
    )
}

const ExpertList = styled.section`
    padding: 5vw 10vw;
    background-color: ${Theme.ui.inverse};
    color: ${Theme.text.inverse};

    @media ${BREAKPOINTS.mobile} {
        display: grid;
        grid-template-columns: 3fr 5fr;
    }
`

const ExpertCopy = styled.div`
    position: relative;
    padding: 1rem;
    border-top: 1px solid ${Theme.ui.primary};
    white-space: pre-wrap;

    @media ${BREAKPOINTS.mobile} {
        flex: 1 1;

        &::after {
            position: absolute;
            top: 1rem;
            bottom: 1rem;
            right: 0;
            width: 0px;
            border-right: 1px solid ${Theme.ui.primary};
            content: ' ';
        }
    }
`

const ExpertsContainer = styled.div`
    overflow: hidden;

    @media ${BREAKPOINTS.mobile} {
        flex: 2 2;
        border-top: 1px solid ${Theme.ui.primary};
    }
`

const Wrapper = styled.div`
    position: relative;
`

const Experts = styled.div`
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    overflow: auto;

    scroll-snap-type: x proximity;
`

const ExpertThumb = styled.figure`
    margin: 0;
    padding: 0 0 0 ${Spacing.md};
    flex: 0 0 60vw;
    width: 60vw;

    scroll-snap-align: start;

    @media ${BREAKPOINTS.mobile} {
        padding: 0 0 0 ${Spacing.lg};
        flex: 0 0 25vw;
        width: 25vw;
    }
`

const Headshot = styled.div``

const Photo = styled(ImageWithSVGSupport)`
    position: relative;
    left: -30%;
    width: 150%;
`

const SliderButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: ${Spacing.xs};
    width: 3rem;
    height: 3rem;
    background: ${Theme.ui.inverse};
    border: 1px solid ${Theme.ui.primary};
    border-radius: 50%;
    cursor: pointer;
`

const RightArrow = styled.img`
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: ${Spacing.xs};
    width: 3rem;
    height: 3rem;
    background: ${Theme.ui.inverse};
    border: 1px solid ${Theme.ui.primary};
    border-radius: 50%;
    cursor: pointer;
`

const SliderArrow = ({ onClick, direction }) => {
    return (
        <>
            {direction === 'right' && (
                <SliderButton onClick={onClick}></SliderButton>
            )}
            {direction === 'left' && (
                <div
                    style={{
                        border: '1px solid black',
                        padding: '1em',
                        backgroundColor: 'white',
                        visibility: 'hidden',
                    }}
                    onClick={onClick}
                >
                    {direction}
                </div>
            )}
        </>
    )
}

export default BlockExpertList
