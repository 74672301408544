import React from 'react'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import Spacing from '../shared/spacing'
import Theme from '../shared/colors'
import { BREAKPOINTS } from '../shared/grid'

import { H1, H2, H3, P, typestyle } from '../shared/typography'
import CallToAction from '../call-to-action'
import ImageWithSVGSupport from '../image-with-svg-support'

const ExpertFormUrl = `https://admin075583.typeform.com/to/AjN4N4qM#requestedexpert=`

const EntryExpert = (
    {
        firstName,
        lastName,
        jobTitle,
        experience,
        headshot,
        summary,
        company,
        indexIsEven,
    },
    ...rest
) => {
    return (
        <Container isEven={indexIsEven}>
            {headshot && (
                <Headshot>
                    <ImageWithSVGSupport
                        file={headshot.file}
                        fluid={headshot.fluid}
                        isEven={indexIsEven}
                    />
                </Headshot>
            )}
            <Info isEven={indexIsEven}>
                <Heading>
                    <ArrowIcon src={'../arrow-right.svg'} />
                    {firstName} {lastName}
                </Heading>
                <Subtitle size={'h4'}>
                    {jobTitle} &mdash; {experience}
                </Subtitle>
                {summary && <Summary>{summary.summary}</Summary>}
                <Footer>
                    <CallToAction
                        inverted={indexIsEven}
                        formUrl={`${ExpertFormUrl}${firstName} ${lastName}`}
                    >
                        Ask {firstName} a Question
                    </CallToAction>
                    {company.logo && company.logoInverse && (
                        <CompanyLogo
                            file={
                                indexIsEven
                                    ? company.logo.file
                                    : company.logoInverse.file
                            }
                            fluid={
                                indexIsEven
                                    ? company.logo.fluid
                                    : company.logoInverse.fluid
                            }
                        />
                    )}
                </Footer>
            </Info>
        </Container>
    )
}

const Container = styled.figure`
    display: block;
    margin: 0;
    padding: 10vw 10vw 4vw;
    background-color: ${(props) =>
        props.isEven ? Theme.ui.inverse : Theme.ui.primary};
    text-align: left;
    color: ${(props) =>
        props.isEven ? Theme.text.inverse : Theme.text.primary};

    @media ${BREAKPOINTS.tablet} {
        display: grid;
        grid-template-columns: 3fr 3fr;
    }

    @media ${BREAKPOINTS.desktop} {
        display: grid;
        grid-template-columns: 3fr 3fr;
    }
`

const Headshot = styled.div`
margin-top: -20%;
    @media ${BREAKPOINTS.tablet} {
        order: ${(props) => (props.isEven ? 1 : 2)};
    }
`

const Info = styled.figcaption`
    @media ${BREAKPOINTS.tablet} {
        padding: ${(props) =>
            props.isEven ? `0 0 0 ${Spacing.lg}` : `0 ${Spacing.lg} 0 0`};
        order: ${(props) => (props.isEven ? 2 : 1)};
    }
`

const Heading = styled(H2)`
    position: relative;
`

const Subtitle = styled(P)`
    font-weight: normal;
`

const Summary = styled(P)`
    white-space: pre-wrap;
`

const Footer = styled.footer`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const ArrowIcon = styled.img`
    position: absolute;
    left: -1em;
    max-height: 2ex;
    max-width: 2ex;
`

const CompanyLogo = styled(ImageWithSVGSupport)`
    display: block;
    flex: 1 1 10%;
    margin: 0 ${Spacing.sm};
    max-width: 100px;
    height: auto;
`

export default EntryExpert
